import '../scss/app.scss'; // Importing SCSS file


document.addEventListener('DOMContentLoaded', function() {

    let currentPage = 1;
    const eventsGrid = document.getElementById('events-grid');
    const eventTemplate = document.getElementById('event-template'); // Get the template element
    const paginationContainer = document.getElementById('pagination-container');
    const paginationButtonTemplate = document.getElementById('pagination-button-template');
    const sectionTitle = document.getElementById('event-section-title');
    const filter = new FilterCategories(".filter-container", ".categories", fetchEvents, isHome);
    const events_section = document.getElementById('events-container');
       
  

    // Fetch events and update the page content
    async function fetchEvents(page = 1, scroll, initLoad = false) {
        try {
            let query =`?page=${page}&per_page=${perPage}&category=${filter.category}`;
            const response = await fetch(`${apiUrl}${query}`);
            const data = await response.json();

            if (data.events) {
                // if initLoad is true, and there are no events hide the section
                if (initLoad && data.events.length === 0) {
                    events_section.style.display = 'none';
                    return;
                }
                renderEvents(data.events, scroll);

                if(!isHome) renderPagination(data.total_pages, page);
            }
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    }

    // Render the fetched events dynamically
    function renderEvents(events, scroll = true) {

   


        // Clear any previous content
        eventsGrid.innerHTML = '';
        

        events.forEach(event => {
       

            // Get formatted date
            const eventDate = new Date(event.date);
             
            let weekDayLongName = eventDate.toLocaleDateString('en-US', { weekday: 'long' });
            let year = eventDate.getFullYear();
            let shortMonthName = eventDate.toLocaleDateString('en-US', { month: 'short' });
            let day = eventDate.getDate();

            let eventDateString = `${weekDayLongName}, ${shortMonthName}. ${day}, ${year}`;
            const eventClone = document.importNode(eventTemplate.content, true);

             event.time  = event.time.replace("am", " a.m.").replace("pm", " p.m.");
            

            // Fill the template with event data
            eventClone.querySelector('.event-title').textContent = event.title;
            eventClone.querySelector('.event-date').innerHTML = eventDateString + '<br />' + event.time;
            eventClone.querySelector('.event-location').textContent =  "Learn More";
            eventClone.querySelector('.event-location').href = event.permalink;
            eventClone.querySelector('img').src = event.image_url;

            // Handle categories
            const categoryContainer = eventClone.querySelector('.event-categories');
            if (event.new) {
                const newCategory = document.createElement('span');
                newCategory.classList.add('event-category');
                newCategory.textContent = 'New';
                categoryContainer.appendChild(newCategory);
            }
            const category = document.createElement('span');
            category.classList.add('event-category');
            let category_addon = event.category.toLowerCase().replace(/\s/g, '-');
            category.classList.add(category_addon);     
            category.textContent = event.category;
            categoryContainer.appendChild(category);

            // Append the cloned and filled template
            eventsGrid.appendChild(eventClone);
        });

        // scroll to top of the events grid

        if(scroll)  sectionTitle.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    // Render pagination buttons
    function renderPagination(totalPages, currentPage) {
    

        // Clear previous pagination
        paginationContainer.innerHTML = '';
 

        // Add page buttons
        for (let i = 1; i <= totalPages; i++) {
            const pageButton = document.importNode(paginationButtonTemplate.content, true);
            const pageButtonElement = pageButton.querySelector('.pagination-button');
            pageButtonElement.textContent = i;
            if (i === currentPage) {
                pageButtonElement.classList.add('active');
            }
            pageButtonElement.addEventListener('click', () => fetchEvents(i));
            paginationContainer.appendChild(pageButton);
        }

       
    }


    fetchEvents(currentPage, false, true);
 

    // Initial fetch
 

   
});
 

class FilterCategories {
    constructor(containerSelector, categoriesSelector, fetchEvents, isHome) {
        if (!isHome) {
            this.container = document.querySelector(containerSelector);
            this.categories = document.querySelector(categoriesSelector);
            this.isOpen = false;
            this.category = null;
        
            this.categoryButtons = this.categories.querySelectorAll('button');
            this.fetchEvents = fetchEvents;

            
        

            if (this.container && this.categories) {
                this.init();
            } else {
                console.error("Invalid selectors provided.");
            }
        }
    }

    init() {
        // Attach a click event listener to the filter container
        this.container.addEventListener("click", () => this.toggleCategories());

        // Attach a click event listener to each category button
        this.categoryButtons.forEach(button => {
            button.addEventListener("click", () => this.categoryButtonHandler(button));
        });
    }

    toggleCategories() {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            this.categories.style.display = "flex";
        } else {
            this.categories.style.display = "none";
        }
    }

    categoryButtonHandler(button) {
        this.category = button.dataset.category;
        this.categoryButtons.forEach(button => button.classList.remove('active'));
        button.classList.add('active');
        this.updateDateCatecory(this.category);
        this.toggleCategories();
    }

    updateDateCatecory(category) {
        this.category = category;
        this.fetchEvents();
    }

}

// Usage example:
// Assuming you are targeting '.filter-container' to open '.categories'


document.addEventListener('DOMContentLoaded', () => {
    // Define the array of image URLs
    const imageUrls = [
        "/wp-content/uploads/2024/12/Online-Calendar-Graphics-06.png",
        "/wp-content/uploads/2024/12/Online-Calendar-Graphics-05.png",
        "/wp-content/uploads/2024/12/Online-Calendar-Graphics-04.png",
        "/wp-content/uploads/2024/12/Online-Calendar-Graphics-03.png",
        "/wp-content/uploads/2024/12/Online-Calendar-Graphics-02.png",
        "/wp-content/uploads/2024/12/Online-Calendar-Graphics-01.png"
    ];


    
    // Check if the current page URL contains '/events/'
    if (window.location.pathname.includes('/event/')) {
        // Find the .tribe-events-event-image img element
        const eventImage = document.querySelector('.tribe-events-event-image img');
        const beforeImageContent = document.querySelector('.tribe-events-single-event-description.tribe-events-content');

        let image = document.createElement('img')

        let eventStart = document.querySelector('.tribe-event-date-start');
        let eventEnd = document.querySelector('.tribe-event-time');
        let eventEndDate = document.querySelector('.tribe-event-date-end');

        // convert am and pm to a.m. and p.m.
        if(eventStart)   eventStart.textContent = eventStart.textContent.replace("am", " a.m.").replace("pm", " p.m.");
        if(eventEnd)     eventEnd.textContent = eventEnd.textContent.replace("am", " a.m.").replace("pm", " p.m.");
        if(eventEndDate) eventEndDate.textContent = eventEndDate.textContent.replace("am", " a.m.").replace("pm", " p.m.");
     
        
        // If the image exists, update it with a randomly selected URL from the array
        if (eventImage) {
          
           
            
            
        } else {
            const randomIndex = Math.floor(Math.random() * imageUrls.length);
            image.src = imageUrls[randomIndex];
            let eventWrapper = document.createElement('div');
            eventWrapper.classList.add('tribe-events-event-image');

            eventWrapper.appendChild(image);
            
            beforeImageContent.insertAdjacentElement('beforebegin',  eventWrapper);
        }
    }
});



document.addEventListener("DOMContentLoaded", function () {
    // Create a new container for consolidated content
    const consolidatedSection = document.createElement("div");
    consolidatedSection.classList.add("tribe-events-consolidated-section");

    // Target the original sections
    const primaryMetaSection = document.querySelector(".tribe-events-event-meta.primary");
    const secondaryMetaSection = document.querySelector(".tribe-events-event-meta.secondary");

    // Collect the inner content of the primary section
    if (primaryMetaSection) {
        consolidatedSection.innerHTML += primaryMetaSection.innerHTML;
    }

    // Collect the inner content of the secondary section
    if (secondaryMetaSection) {
        consolidatedSection.innerHTML += secondaryMetaSection.innerHTML;
    }

    // Add the consolidated content to the DOM
    const parentContainer = document.querySelector(".tribe-events-single-section");
    if (parentContainer) {
        parentContainer.parentNode.insertBefore(consolidatedSection, parentContainer);
    }

    // Optionally, remove the original sections
    if (primaryMetaSection) primaryMetaSection.remove();
    if (secondaryMetaSection) secondaryMetaSection.remove();

    consolidatedSection.className = "tribe-events-single-section tribe-events-event-meta primary tribe-clearfix";
});
